import { FunctionComponent, useState } from 'react'
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import Card from 'components/common/Card'
import { FormattedMessage } from 'react-intl'
import { InvestmentPlan } from '../InvestmentPlan'
import { ButtonWrapper, Row, useStyles } from './styled'
import Money from 'assets/money.svg'
import ArrowRight from 'assets/arrow_right_black.svg'
import ArrowRightDisabled from 'assets/arrow_right_disabled.svg'
import { StepNumber } from 'global/types'
import { Plan } from 'types'
import { getArraysChunks } from 'utils/PlanChunks'

const { FIRST } = StepNumber

interface Props {
  handleStepNumber: (value: number) => void
  isButtonDisabled: (value: boolean) => void
  buttonDisabled: boolean
  plans: Plan[]
  selectInvestmentPlan: (item: Plan | null) => void
  setCapitalToFund: (value: string) => void
  capitalToFund: string
  investmentPlanSelected: Plan | null
  isDisabledChangeAmount: boolean
}

const InvestmentAmount: FunctionComponent<Props> = ({
  handleStepNumber,
  isButtonDisabled,
  buttonDisabled,
  plans,
  selectInvestmentPlan,
  setCapitalToFund,
  capitalToFund,
  investmentPlanSelected,
  isDisabledChangeAmount,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isResponsive = useMediaQuery(theme.breakpoints.down('md'))

  const [amount, setAmount] = useState<string>(capitalToFund)
  const [minimalAmount, setMinimalAmount] = useState<number>(0)

  const handleInputAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value
    const value = inputValue.replace(/\D/g, '') || '0'
    if (value.length > 13) return
    const numberValue = Number(value)
    setMinimalAmount(numberValue)
    validateInput(numberValue)
  }

  const validateInput = (value: number) => {
    const formattedValue = new Intl.NumberFormat('en-US', { currency: 'USD' }).format(value)

    setAmount(formattedValue)
    setCapitalToFund(formattedValue)

    if (
      Number(investmentPlanSelected?.minimumInitialInvestment) > value ||
      (investmentPlanSelected?.maximumInvestment &&
        Number(investmentPlanSelected?.maximumInvestment) < value)
    ) {
      isButtonDisabled(true)
      selectInvestmentPlan(null)
    }
  }

  const onClickContinueButton = () => {
    setCapitalToFund(amount)
    handleStepNumber(FIRST)
  }

  return (
    <Card className={classes.mainGridCard}>
      <Typography variant="h4">
        <FormattedMessage id="screen.newinvestment.subtitle" />
      </Typography>
      <Typography variant="body3" className={classes.body3}>
        <FormattedMessage id="screen.newinvestment.paragraph" />
      </Typography>
      <FormControl>
        {amount.length > 0 && (
          <InputLabel htmlFor="outlined-adornment-amount">
            <FormattedMessage id="screen.newinvestment.labelAmount" />
          </InputLabel>
        )}
        <OutlinedInput
          className={classes.moneyInput}
          id="outlined-adornment-amount"
          value={amount}
          onChange={handleInputAmount}
          startAdornment={<img src={Money} alt="money" />}
          label={amount.length > 0 ? 'Amount' : ''}
          autoFocus={true}
          placeholder="Amount"
          disabled={isDisabledChangeAmount}
        />
      </FormControl>
      <Typography variant="body3" className={classes.body3}>
        <FormattedMessage id="screen.newinvestment.terms.additionalDeposits" />
      </Typography>
      <Typography variant="h4" className={classes.investmentPlan}>
        <FormattedMessage id="screen.newinvestment.investmentPlan" />
      </Typography>
      {getArraysChunks(plans, 3).map((plans, index) => (
        <Row key={`row-${index}`} className={classes.planRows}>
          {plans.map((item: Plan) => (
            <InvestmentPlan
              key={item.uuid}
              plan={item}
              setButtonDisabled={isButtonDisabled}
              amount={minimalAmount}
              selectInvestmentPlan={selectInvestmentPlan}
              isSelected={!!investmentPlanSelected && investmentPlanSelected.uuid === item.uuid}
            />
          ))}
        </Row>
      ))}
      {!isResponsive && (
        <ButtonWrapper className={classes.continueButton}>
          <Button
            disabled={buttonDisabled}
            variant="contained"
            onClick={onClickContinueButton}
            endIcon={
              <img src={buttonDisabled ? ArrowRightDisabled : ArrowRight} alt="arrowRight" />
            }
            className={classes.continueButton}
          >
            <FormattedMessage id="screen.newinvestment.buttonContinue" />
          </Button>
        </ButtonWrapper>
      )}
    </Card>
  )
}

export default InvestmentAmount
